<template>
  <default-view-component>
    <template v-slot:content>
      <div class="content">
        <default-section-component class="default-section-component">
          <template v-slot:content>
            <div class="message-container">
              <div class="success">
                <h1>
                  Pré-contrato <span>{{ contractId }}</span> criado com sucesso!
                </h1>

                <div class="option-container">
                  <icon-button-component
                    v-if="!getIsExternalAuth"
                    class="option"
                    iconName="icon-file"
                    text="Assinar agora"
                    @callback="toDigitalContract"
                  />

                  <icon-button-component
                    class="option"
                    iconName="icon-copy-outline"
                    text="Copiar link"
                    @callback="copyToClipboard"
                  />
                </div>
              </div>
            </div>
           </template>
        </default-section-component>
      </div>
    </template>
  </default-view-component>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/base/DefaultView';
  import DefaultSection from '@/components/base/DefaultSection';
  import IconButton from '@/components/IconButton';

  export default {
    name: 'NewSuccessPage',

    components: {
      'default-view-component': DefaultView,
      'default-section-component': DefaultSection,
      'icon-button-component': IconButton
    },

    data() {
      return {
        digitalContractId: '',
        contractId: ''
      };
    },

    computed: {
      ...mapGetters({
        getIsExternalAuth: 'authModule/getIsExternalAuth'
      })
    },

    created() {
      if (this.$route.params.contractId) {
        this.contractId = this.$route.params.contractId;
      } else {
        this.toNew();
      }
    },

    methods: {
      toNew() {
        this.$router.push('/precontract/new');
      },

      toDigitalContract() {
        this.$router.push({
          name: 'signDigitalContract',
          params: {
            contractId: this.contractId,
          }
        });
      },

      copyToClipboard() {
        let url = `${window.location.origin}/precontract/${this.contractId}/contract/sign`;

        this.$copyText(url);

        if (this.getIsExternalAuth) {
          parent.postMessage({
            type: 'im-contract-clicked-copy-sign-button',
            data: {
              sign_url: url
            }
          }, '*');
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;

    .default-section-component {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      max-width: 672px;
      width: 100%;

      .message-container {
        .success {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          h1 {
            font-size: 1.2rem;

            span  {
              color: #FF9C00;
            }
          }

          .qr-code {
            margin-top: 1rem;
          }

          .option-container {
            display: flex;
            margin-top: 1.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 720px) {
    .content {
      padding: 0 1.5rem;

      .default-section-component  {
        width: 100%;
      }
    }
  }

  @media (max-width: 620px) {
    .content {
      .default-section-component {
        .success {
          h1 {
            font-size: 1.1rem !important;
          }

          .option-container {
            flex-direction: column;

            .option {
              margin-bottom: 1rem;
              margin-right: 0;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>