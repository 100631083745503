<template>
  <div class="default-section-container">
    <h1 v-if="!!title">
      {{ title }}
    </h1>

    <slot name="content"/>
  </div>
</template>

<script>
  export default {
    name: 'DefaultSection',

    props: {
      title: {
        type: String
      }
    }
  };
</script>

<style lang="scss" scoped>
  .default-section-container {
    padding: 2rem 1.5rem;
    background: #EFEFEF;
    width: 100%;
    border-radius: 1rem;

    h1 {
      font-size: 20px;
      font-weight: 600;
    }
  }

  @media (max-width: 520px) {
    .default-section-container {
      padding: 1.5rem 1rem;

      h1 {
        font-size: 16px;
      }
    }
  }
</style>