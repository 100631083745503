<template>
  <default-view-component
    :showAlertCard="showAlertCard"
    :alertCardData="alertCardData"
    @hideAlertCard="redirect"
  >
    <template v-slot:content>
      <div class="content">
        <spinner-component
          v-if="hasRequest"
          class="spinner-component"
          :styles="spinner"
        />

        <iframe
          ref="contract"
          :style="{ visibility: hasRequest ? 'hidden' : 'visible' }"
          :src="uri"
          width="100%"
          height="100%"
        />
      </div>
    </template>
  </default-view-component>
</template>

<script>
  import DefaultView from '@/components/base/DefaultView';
  import Spinner from '@/components/Spinner';

  export default {
    name: 'DigitalContractPage',

    data() {
      return {
        hasRequest: true,
        uri: '',
        contractId: '',
        frame: '',

        spinner: {
          size: '4px',
          color: '#FEAA3B',
          borderSize: '1em',
        },

        showAlertCard: false,
        alertCardData: {},
      };
    },

    components: {
      'default-view-component': DefaultView,
      'spinner-component': Spinner
    },

    created() {
      if (this.$route.params.contractId) {
        this.contractId = this.$route.params.contractId;
        this.fetchContract(this.contractId);
      } else {
        this.$router.push('/precontract/new');
      }
    },

    mounted() {
      window.addEventListener('message', this.receiveMessage);
    },

    beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage);
    },

    methods: {
      receiveMessage(event) {
        var eventData = event.data;

        switch (eventData) {
          case 'zs-doc-loaded':
            this.hasRequest = false;
            break;
          case 'zs-doc-signed':
            this.hasRequest = true;
            this.createCheckout(this.contractId);
            break;
        }
      },

      async createCheckout(contractId) {
        await this.$store.dispatch('postCreateCheckout', contractId)
          .then(()  => {
            this.toCheckout();
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao criar link de pagamento',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      async fetchContract(contractId) {
        await this.$store.dispatch('getContract', contractId)
          .then(res => {
            if (res.data.id_checkout) {
              this.toCheckout();
            } else if (this.$service.toUpperCase(res.data.StatusDocumento) == 'PENDENTE' || this.$service.toUpperCase(res.data.StatusDocumento) == 'PENDING') {
              this.uri = res.data.linkAluno;
            } else if (!res.data.id_checkout && this.$service.toUpperCase(res.data.StatusDocumento) == 'ASSINADO' || this.$service.toUpperCase(res.data.StatusDocumento) == 'SIGNED') {
              this.createCheckout(contractId);
            } else {
              this.redirect();
            }
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao buscar detalhes do contrato atual',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      toCheckout() {
        this.$router.push(`/precontract/${this.contractId}/checkout/pay`);
      },

      redirect() {
        this.showAlertCard = !this.showAlertCard;

        this.$router.push(`/precontract/${this.contractId}`);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    height: 100%;

    .spinner-component {
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    iframe {
      border-width: 0;
    }
  }
</style>