<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="name"
    :rules="rules"
    class="select-container"
    v-slot="{ errors, required }"
  >
    <div>
      <p>
        {{ label }}

        <span>
          {{ required ? '*' : '' }}
        </span>
      </p>
    </div>

    <div class="select-content">
      <select
        :class="{ 'error': !!errors[0], 'disabled': isDisabled }"
        :disabled="isDisabled"
        :value="innerValue"
        v-model="innerValue"
      >
        <option hidden selected value="">
          Selecione
        </option>

        <option
          v-for="(item, index) in option"
          :value="item.value"
          :key="index"
        >
          {{ item.label }}
        </option>
      </select>

      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'SelectField',

    props: {
      label: {
        type: String,
        default: ''
      },

      vid: {
        type: String,
        default: undefined
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      value: {
        type: String,
        default: ''
      },

      option: {
        type: Array
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ValidationProvider
    },

    data() {
      return {
        name: null,
        open: false,
        innerValue: this.value
      };
    },

    watch: {
      innerValue(value) {
        this.$emit('input', value);
      },

      option() {
        this.innerValue = '';
      }
    },

    mounted() {
      this.name = this.refName ?? this.label.toLowerCase();
    }
  };
</script>

<style lang="scss" scoped>
  .select-container {
    width: 100%;

    p {
      margin-bottom: 0.5rem;
    }

    span {
      color: #b93a5b;
    }

    .select-content {
      select {
        height: 48px;
        color: #333;
        border: 1px solid #DADADA;
        align-items: center;
        font-size: 14px;
        display: flex;
        border-radius: 6px;
        padding: 0 1rem;
        cursor: pointer;
        user-select: none;
        width: 100%;
        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23333333' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat #FFF;
        background-position: calc(100% - 0.6rem) center !important;
        -moz-appearance:none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 1.5rem !important;

        &.disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }

        &.error {
          border: 2px solid #b93a5b;
        }
      }

      span {
        color: #b93a5b;
        font-size: 12px;
        margin-top: 0.2rem;
        display: flex;
        min-width: 190px;
        position: absolute;
      }
    }
  }
</style>