<template>
  <div class="card">
    <div class="icon-container" :style="{ background: styles.backgroundIcon }">
      <div class="icon">
        <i
          :class="styles.icon"
        />
      </div>
    </div>

    <div class="header">
      {{ styles.title }}
    </div>

    <div v-if="!!styles.subTitle" class="subheader">
      {{ styles.subTitle }}

      <a v-if="!!styles.link" :href="styles.link">tente novamente</a>

      {{ styles.afterLink }}
    </div>

    <div v-if="!!styles.items" class="options-container">
      <div class="title">
        {{ styles.items.title }}
      </div>

      <ul class="options">
        <li class="option" v-for="(item, key) in styles.items.itemsArray" :key="key">
          {{ item }}
        </li>
      </ul>
    </div>

    <div v-if="!!styles.transaction">
      <div class="subheader" :style="{ 'word-break': 'break-all'}">
        <b>Identificação da transação:</b>

        {{ styles.transaction.id }}
      </div>

      <div v-if="!!styles.transaction.date" class="subheader date">
        {{ styles.transaction.date }}
      </div>
    </div>

    <div v-if="!!styles.footerText" class="text">
      {{ styles.footerText }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CardComponent',

    data() {
      return {
        hasData: false,
      };
    },

    props: {
      styles: {
        type: Object,
        default() {
          return {
            icon: 'icon-check',
            backgroundIcon: '#DADADA',
            title: 'title',
            subTitle: 'subtitle',
            link: '',
            afterLink: 'secondtitle',
            items: {
              title: 'title',
              itemsArray: [
                'item 1',
                'item 2',
                'item 3'
              ]
            },
            transaction: {
              id: 'transactionId',
              date: 'NaN'
            },
            footerText: 'footer text'
          };
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 5%);
    padding: 3rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 1.5rem;
    margin-bottom: 2rem;

    .icon-container {
      margin-top: 1.5rem;
      border-radius: 6rem;
      width: 7rem;
      height: 7rem;

      justify-content: center;
      align-items: center;
      display: flex;

      .icon {
        i {
          font-size: 3.5rem;
          color: #FFF;
        }
      }
    }

    .header {
      color: #6d6f6f;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .subheader {
      color: #6d6f6f;
      text-align: center;
      margin-bottom: 1.5rem;
      max-width: 700px;

      b {
        font-size: 16px;
      }

      a {
        color: #0052cc;
      }

      &.date {
        margin-top: -1rem;
      }
    }

    .options-container {
      background: #efefef;
      color: #333;
      padding: 1rem;
      border-radius: 1rem;

      .title {
        font-size: 1rem;
        margin: 0;
        margin-bottom: 0.7rem;
      }

      .options {
        color: #a0a0a0;

        .option {
          margin-left: 1.4rem;
        }
      }
    }

    .text {
      color: #6d6f6f;
      text-align: center;
      margin-top: 1.5rem;
    }
  }

  @media (max-width: 768px) {
    .card {
      .icon-container {
        width: 5rem;
        height: 5rem;

        .icon {
          i {
            font-size: 3rem;

            &.icon-check {
              font-size: 2rem;
            }
          }
        }
      }

      .header {
        text-align: center;
      }
    }
  }

  @media (max-width: 520px) {
    .card {
      padding: 3rem 1rem;
    }
  }
</style>