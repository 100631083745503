import { extend } from 'vee-validate';
import { cpf } from 'cpf-cnpj-validator';
import moment from 'moment';
import { required, email, confirmed, min } from 'vee-validate/dist/rules';
import Service from './service';

extend('required', {
  ...required,
  message: 'Campo obrigatório'
});

extend('confirmed', {
  ...confirmed,
  message: 'Senhas não conferem'
});

extend('min', {
  ...min,
  message: 'Campo deve ter no mínimo {length} caracteres'
});

extend('email', {
  ...email,
  message: 'E-mail inválido'
});

extend('cpf', {
  message: 'CPF inválido',
  validate: value => {
    if (value != null) {
      let strCpf = value.replace(/\.|-/g, '');

      return cpf.isValid(strCpf);
    }
  }
});

extend('postalCode', {
  message: 'CEP inválido',
  validate: value => {
    if (value != null) {
      return Service.removeSpecialCharacter(value).length === 8;
    }
  }
});

extend('completedName', {
  message: 'Digite o nome completo',
  validate: value => {
    if (value != null && value.indexOf(' ') >= 0) {
      let regName = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;

      return value.match(regName);
    }
  }
});

extend('date', {
  message: 'Data inválida',
  validate: date => {
    var formattedDate = moment(date, 'DD/MM/YYYY').add(1, 'day');

    return !moment(formattedDate).isBefore(moment());
  }
});

extend('phone', {
  message: 'Número inválido',
  validate: phone => {
    let strPhone = phone.replace(/[^0-9]/g, '');

    return strPhone.length == 10 || strPhone.length == 11;
  }
});

extend('phone', {
  message: 'Número inválido',
  validate: phone => {
    let strPhone = phone.replace(/[^0-9]/g, '');

    return strPhone.length == 10 || strPhone.length == 11;
  }
});

extend('maxValue', {
  message: (_, args) => 'Valor máximo ' + Service.formatBrlAmountSymbol(args[0].split(':')[0]),
  validate: (_, args) => {
    var splitString = args[0].split(':');
    var minValue = parseFloat(splitString[1]);
    var maxValue = parseFloat(splitString[0]);

    return minValue <= maxValue;
  }
});

extend('minValue', {
  message: 'Valor incorreto',
  validate: (_, args) => {
    var splitString = args[0].split(':');
    var minValue = parseFloat(splitString[1]);
    var maxValue = parseFloat(splitString[0]);

    return minValue < maxValue;
  }
});

extend('notZero', {
  message: 'Valor incorreto',
  validate: value => {
    if (value != null) {
      const valueAsString = value.toString();
      const numericValue = valueAsString.replace(/[^\d]/g, '');

      return Number(numericValue);
    }
  }
});