<template>
  <div class="toggle-switch-container" :class="{ 'disabled': isDisabled }">
    <input
      type="checkbox"
      :disabled="isDisabled"
      v-model="toggleValue"
      @change="handleChange"
    >
  </div>
</template>

<script>
  export default {
    name: 'ToggleSwitch',

    data() {
      return {
        toggleValue: this.value,
      };
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },

      isDisabled: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      value(value) {
        this.toggleValue = value;
      }
    },

    methods: {
      handleChange() {
        if (!this.isDisabled) {
          this.$emit('input', this.toggleValue);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .toggle-switch-container {
    display: flex;

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      border: none;
      position: relative;
      border: 0;
      outline: 0;
      cursor: pointer;
      display: flex;

      &:after {
        content: '';
        width: 40px;
        height: 20px;
        background: #DADADA;
        border-radius: 15px;
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        top: 2px;
        left: 3px;
        border-radius: 50%;
        background: #FFF;
      }

      &:checked:before {
        left: 21px;
      }

      &:checked:after {
        background: #FF9C00;
      }

      &:before,
      &:after,
      &:checked:before,
      &:checked:after {
        transition: ease .3s;
        -webkit-transition: ease .3s;
        -moz-transition: ease .3s;
        -o-transition: ease .3s;
      }
    }

    &.disabled {
      opacity: 0.5;

      input {
        cursor: not-allowed;
      }
    }
  }
</style>