<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="name"
    :rules="rules"
    class="static-input-container"
    :class="styles"
    v-slot="{ errors, required }"
  >
    <div>
      <p>
        {{ label }}

        <span>
          {{ required ? '*' : '' }}
        </span>
      </p>
    </div>

    <div class="input-container" :class="{ 'icon': hasIcon() }">
      <input
        v-if="isMoney()"
        :type="inputType"
        :placeholder="placeholder"
        v-model="innerValue"
        autocomplete="off"
        :value="value"
        :disabled="isDisabled"
        :maxlength="maxLength"
        :class="{ 'error': !!errors[0], 'disabled': isDisabled }"
        @input="checkMoney"
      />

      <v-date-picker
        v-else-if="isDate()"
        v-model="innerValue"
        locale="pt-BR"
        :min-date="minCalendarDate"
        :first-day-of-week="1"
        :popover="{ visibility: isDisabled ? 'hide' : 'hover' }"
        color="orange"
        :masks="{
          input: 'DD/MM/YYYY'
        }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            v-on="inputEvents"
            :value="inputValue"
            :disabled="isDisabled"
            :placeholder="placeholder"
            :maxlength="10"
            class="money"
            :class="{ 'error': !!errors[0], 'disabled': isDisabled }"
            readonly
          />
        </template>
      </v-date-picker>

      <input
        v-else-if="hasMask()"
        :type="inputType"
        :placeholder="placeholder"
        v-model="innerValue"
        autocomplete="off"
        v-mask="mask"
        :value="value"
        :disabled="isDisabled"
        :maxlength="maxLength"
        :class="{ 'error': !!errors[0], 'disabled': isDisabled }"
      />

      <input
        v-else
        :type="inputType"
        :placeholder="placeholder"
        v-model="innerValue"
        autocomplete="off"
        :value="value"
        :disabled="isDisabled"
        :maxlength="maxLength"
        :class="{ 'passwd': isPassword(), 'error': !!errors[0], 'disabled': isDisabled }"
      />

      <div
        v-if="hasIcon() && !isDisabled"
        class="icon-container"
        @click="inputButtonClick"
      >
        <i v-if="hasButtonCleaner" class="icon-close-circle" />
        <i v-else-if="isPassword() && visibility" class="icon-eye" />
        <i v-else class="icon-eye-slash" />
      </div>

      <p>{{ errors[0] }}</p>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'InputField',

    props: {
      type: {
        type: String,
        default: 'text',
        validator(value) {
          return [
            'url',
            'text',
            'password',
            'tel',
            'search',
            'number',
            'email',
            'money',
            'date'
          ].includes(value);
        },
      },

      vid: {
        type: String,
        default: undefined
      },

      placeholder: {
        type: String,
        required: true
      },

      label: {
        type: String,
        default: ''
      },

      styles: {
        type: String,
        default: ''
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      value: {
        type: [String, Number, Date],
        default: '',
      },

      maxLength: {
        type: Number,
        required: false
      },

      mask: {
        type: [String, Array],
        required: false
      },

      minCalendarDate: {
        type: [String, Date],
        default: (value) => { return value ? '' : new Date(); }
      },

      refName: {
        type: String,
        required: false
      },

      isDisabled: {
        type: Boolean,
        default: false
      },

      hasButtonCleaner: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ValidationProvider,
    },

    data() {
      return {
        name: null,
        innerValue: '',
        visibility: false,
        inputType: 'text'
      };
    },

    created() {
      this.inputType = this.type;

      if (this.value) {
        this.innerValue = this.value;
      }
    },

    mounted() {
      if (this.refName) {
        this.name = this.refName;
      } else {
        this.name = this.label.toLowerCase();
      }

      if (this.isMoney() && this.value) {
        this.innerValue = parseInt(this.innerValue) * 100;

        this.checkMoney();
      }
    },

    watch: {
      innerValue(value) {
        this.$emit('input', this.isMoney() ? parseInt(this.$service.removeSpecialCharacter(value)) / 100 : value);
      },

      value(value) {
        if (value !== this.innerValue && !this.isMoney()) {
          this.innerValue = value;
        }
      }
    },

    methods: {
      hasInnerValue() {
       return !!this.innerValue;
      },

      isPassword() {
        return this.type == 'password';
      },

      isMoney() {
        return this.type == 'money';
      },

      isDate() {
        return this.type == 'date';
      },

      toggleVisibility() {
        if (this.visibility && !this.isDisabled) {
          this.visibility = false;
          this.inputType = 'password';
        } else {
          this.visibility = true;
          this.inputType = 'text';
        }
      },

      inputButtonClick() {
        if (!this.isDisabled) {
          if (this.isPassword()) {
            this.toggleVisibility();
          } else {
            this.innerValue = '';
          }
        }
      },

      hasMask() {
        return !!this.mask;
      },

      hasIcon() {
        return (this.isPassword() || this.hasButtonCleaner) && this.hasInnerValue();
      },

      checkMoney() {
        var parsedValue = this.$service.removeSpecialCharacter(this.innerValue) / 100;

        this.innerValue = this.$service.formatBrlAmountSymbol(parsedValue);
      },

      resetField() {
        this.innerValue = '';
      }
    }
  };
</script>

<style lang="scss" scoped>
  .static-input-container {
    width: 100%;

    p {
      margin-bottom: 0.5rem;
    }

    span {
      color: #b93a5b;
    }

    .input-container {
      &.icon {
        input {
          padding: 1rem 2.5rem 1rem 1rem;
        }
      }

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 48px;
        border: 1px solid #DADADA;
        background: #FFF;
        border-radius: 6px;
        padding: 1rem;
        font-size: 14px;
        caret-color: #FF9C00;

        &.money {
          cursor: pointer;
        }

        &.disabled {
          opacity: 0.8;
          cursor: not-allowed;
          border-color: #DADADA;
          background: #F9F9F9;
        }

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &.passwd {
          padding: 0.5rem 3rem 0.5rem 1rem;
        }

        &.error {
          border: 2px solid #b93a5b;
        }
      }

      .icon-container {
        color: #8E8E8E;;
        float: right;
        position: relative;
        bottom: 35px;
        right: 15px;
        height: 0;
        cursor: pointer;

        i {
          font-size: 1.5rem;
          position: relative;

          &.icon-close-circle {
            font-size: 1.2rem;
            top: 1.5px;
          }
        }
      }

      p {
        color: #b93a5b;
        font-size: 12px;
        margin-top: 0.2rem;
        display: flex;
        min-width: 190px;
        position: absolute;
      }
    }
  }
</style>