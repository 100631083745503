import { instanceImNic } from '../../config';
import axios from 'axios';

function initialState() {
  return {
    token: localStorage.getItem('token'),
    isExternalAuth: false
  };
}

const actions = {
  setAuth({ commit, dispatch }, { token, username }) {
    return new Promise((resolve, reject) => {
      localStorage.setItem('token', token);

      axios.defaults.headers.common['Authorization'] = token;

      commit('setExternalValue', true);

      dispatch('getFranchise', {
        login: username,
        limit_items: 5000
      }, { root: true })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  postLogin(_, data) {
    return new Promise((resolve, reject) => {
      instanceImNic().post('/auth', JSON.stringify(data))
        .then(response => {
          var token = response.access_token;

          localStorage.setItem('token', token);

          axios.defaults.headers.common['Authorization'] = token;

          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  getIsExternalAuth(state) {
    return state.isExternalAuth;
  }
};

const mutations = {
  setExternalValue(state, value) {
    state.isExternalAuth = value;
  },

  setLogOut(state) {
    const inital = initialState();

    Object.keys(inital).forEach(key => {
      state[key] = inital[key];
    });
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations,
};