<template>
  <div class="container" :class="{ 'disabled': isDisabled }" @click="onClick">
    <i v-if="isCheck" class="icon-check" />

    <div v-else class="content">
      <i :class="iconName" />

      {{ text }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'IconButton',

    data() {
      return {
        isDisabled: false,
        isCheck: false
      };
    },

    props: {
      text: {
        type: String,
        default: 'default'
      },

      iconName: {
        type: String,
        default: 'icon-copy-outline'
      }
    },

    methods: {
      onClick() {
        if (!this.isDisabled) {
          this.isDisabled = true;
          this.isCheck = true;

          this.$emit('callback');

          setTimeout(() => {
            this.isDisabled = false;
            this.isCheck = false;
          }, 500);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    margin-right: 1rem;
    background: #FFF;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    width: 175px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      margin-right: 0.5rem;
      color: #6d6f6f;

      &.icon-check {
        color: #08B978;
        font-size: 1.3rem;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }
</style>