<template>
  <default-view-component
    :showAlertCard="showAlertCard"
    :alertCardData="alertCardData"
    :hideFooter="true"
    @hideAlertCard="redirect"
  >
    <template v-slot:content>
      <div class="content">
        <spinner-component
          v-if="hasRequest"
          class="spinner-component"
          :styles="spinner"
        />

        <iframe
          title="Checkout"
          :style="{ visibility: hasRequest ? 'hidden' : 'visible' }"
          :src="mountCheckoutUri(checkoutId, isCheckoutFd)"
          width="100%"
          height="100%"
        />
      </div>
    </template>
  </default-view-component>
</template>

<script>
  import DefaultView from '@/components/base/DefaultView';
  import Spinner from '@/components/Spinner';

  export default {
    name: 'CheckoutPage',

    data() {
      return {
        hasRequest: true,
        contractId: '',

        checkoutId: '',
        isCheckoutFd: false,

        spinner: {
          size: '4px',
          color: '#FEAA3B',
          borderSize: '1em',
        },

        showAlertCard: false,
        alertCardData: {},
      };
    },

    components: {
      'default-view-component': DefaultView,
      'spinner-component': Spinner,
    },

    created() {
      if (this.$route.params.contractId) {
        this.contractId = this.$route.params.contractId;
        this.fetchContract(this.contractId);
      } else {
        this.$router.push('/precontract/new');
      }
    },

    mounted() {
      window.addEventListener('message', this.receiveMessage);
    },

    beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage);
    },

    methods: {
      receiveMessage(event) {
        switch (event.data) {
          case 'impay-checkout-loaded':
            this.hasRequest = false;
            break;
        }
      },

      async fetchContract(contractId) {
        await this.$store.dispatch('getContract', contractId)
          .then(res => {
            if (!res.data.id_checkout) {
              if (res.data.is_checkout_fd) {
                this.toAcceptTerms();
              } else {
                this.toContractSign();
              }
            } else {
              this.checkoutId = res.data.id_checkout;
              this.isCheckoutFd = res.data.is_checkout_fd == 1;
            }
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao buscar detalhes do contrato atual',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      mountCheckoutUri(checkoutId, isCheckoutFd) {
        const baseUrl = process.env.VUE_APP_BASE_CHECKOUT_IMPAY_URL;
        const checkoutPrefix = isCheckoutFd ? 'ecommerce/checkout' : 'checkout';

        return `${baseUrl}/${checkoutPrefix}/${checkoutId}?hideNavbar=true&hideFooter=true`;
      },

      toContractSign() {
        this.$router.push(`/precontract/${this.contractId}/contract/sign`);
      },

      toAcceptTerms() {
        this.$router.push(`/precontract/${this.contractId}/accept/terms`);
      },

      redirect() {
        this.showAlertCard = !this.showAlertCard;

        this.$router.push(`/precontract/${this.contractId}`);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    height: 100%;
    overflow: hidden;

    .spinner-component {
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    iframe {
      border-width: 0;
    }
  }
</style>