<template>
  <div class="login">
    <div class="image-container" :style="{ 'background': 'center url(' + require('@/assets/images/image-background.png') + ')' }">
      <img src="@/assets/images/login-image.png">
    </div>

    <div class="form-container">
      <div class="logo-container">
        <img src="@/assets/images/logo-red.png">
      </div>

      <ValidationObserver
        tag="div"
        class="form"
        ref="form"
        v-slot="{ passes }"
      >
        <form @submit.prevent="passes(login)">
          <h3>Acessar</h3>

          <input-field
            label="Usuário"
            refName="username"
            class="input-field"
            placeholder="Digite seu usuário"
            rules="required"
            :isDisabled="hasRequest"
            @keydown.native.space.prevent
            v-model="data.username"
          />

          <input-field
            label="Senha"
            placeholder="Digite sua senha"
            rules="required"
            :isDisabled="hasRequest"
            v-model="data.password"
            type="password"
          />

          <button-component
            text="Acessar"
            styles="primary"
            class="button-component"
            size="large"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            :hasLoader="hasRequest"
            :isDisabled="hasRequest"
          />
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';
  import { ValidationObserver } from 'vee-validate';

  export default {
    name: 'LoginPage',

    data() {
      return {
        data: {
          username: '',
          password: '',
        },

        hasError: false,
        hasRequest: false,
      };
    },

    components: {
      ValidationObserver,
      'button-component': Button,
      'input-field': InputField,
    },

    created() {
      var token = this.$route.query.token;
      var username = this.$route.query.username;
      var referenceId = this.$route.query.reference_id;

      var name = this.$route.query.name;
      var phone = this.$route.query.phone;
      var email = this.$route.query.email;
      var taxpayerId = this.$route.query.taxpayerId;
      var postalCode = this.$route.query.postalCode;
      var isResponsible = this.$route.query.isResponsible;
      var franchiseId = this.$route.query.franchiseId;
      var studentName = this.$route.query.studentName;
      var studentTaxpayerId = this.$route.query.studentTaxpayerId;

      if (token && username) {
        this.$store.dispatch('authModule/setAuth', { token, username })
          .then(() => {
            this.$store.commit('setCollaboratorId', this.$service.removeSpecialCharacter(username));

            this.$router.push({
              name: 'new',
              params: {
                fistExternalLogin: true,
                referenceId: referenceId ?? '',
                name: name ?? '',
                email: email ?? '',
                phone: phone ?? '',
                taxpayerId: taxpayerId ?? '',
                postalCode: postalCode ?? '',
                franchiseId: franchiseId ?? '',
                isResponsible: isResponsible ?? false,
                studentName: studentName ?? '',
                studentTaxpayerId: studentTaxpayerId ?? ''
              }
            });

            parent.postMessage({ type: 'im-contract-loaded-successfully' }, '*');
          })
          .catch(() => {
            this.$store.dispatch('logout');
            parent.postMessage({ type: 'im-contract-loaded-error' }, '*');
          });
      }
    },

    methods: {
      async login() {
        var isAuthenticated = false;

        this.hasRequest = true;

        await this.$store.dispatch('authModule/postLogin', this.data)
          .then(() => {
            isAuthenticated = true;
          })
          .catch(() => {
            this.$refs.form.setErrors({
              username: 'Usuário ou senha inválido',
            });
          });

        if (isAuthenticated) {
          await this.$store.dispatch('getFranchise', {
            login: this.data.username,
            limit_items: 5000
          })
            .then(() => {
              this.$router.push('/precontract/new');
            })
            .catch(() => {
              this.$refs.form.setErrors({
                username: 'Ocorreu um erro. Tente novamente',
              });

              this.$store.dispatch('logout');
            });
        }

        this.hasRequest = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .login {
    grid-template-columns: 65% 35%;
    display: grid;
    height: 100vh;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      background: #e72c63;

      img {
        width: 60rem;
      }
    }

    .form-container {
      background: #FFF;
      padding: 1.5rem;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      .logo-container {
        margin-bottom: 2rem;

        img {
          width: 245px;
          height: 60px;
        }
      }

      .form {
        color: #333;
        width: 100%;
        max-width: 25rem;

        form {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: flex;
          width: 100%;

          h3 {
            margin-bottom: 1rem;
          }

          .input-field {
            margin-bottom: 2rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .button-component {
          margin-top: 2.5rem;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .login {
      grid-template-columns: 1fr 100%;

      .form-container {
        border-top: 10px solid #db1a7e;

        .form {
          width: 60%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .login {
      .form-container {
        .form {
          width: 100%;
        }
      }
    }
  }
</style>