import moment from 'moment';
import { cpf } from 'cpf-cnpj-validator';

export default {
  removeSpecialCharacter(str) {
    str = str.toString();

    return str && str.replace(/[^0-9]/g, '');
  },

  dateFormat(date, toFormat = 'YYYY-MM-DD', currentFormat = null) {
    return moment(date, currentFormat).format(toFormat);
  },

  capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  },

  stringToBoolean(str) {
    return str && str.toLowerCase() === 'false' ? false : Boolean(str);
  },

  toUpperCase(str) {
    return str.toUpperCase();
  },

  formatBrlAmountSymbol(amount) {
    return parseFloat(amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },

  formatBrlAmountNoSymbol(amount) {
    return parseFloat(amount).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  },

  validateTaxpayerId(str) {
    return cpf.isValid(str);
  }
};