import axios from 'axios';
import Vue from 'vue';
import router from '@/router';
import store from '@/store/index.js';

export const instanceImNic = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_IMNIC_URL,
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Token': localStorage.getItem('token')
    }
  });

  instance.interceptors.response.use(
    (response) => {
      var responseData = response.data;

      if (response.data.status === 401) {
        Vue.cookie.delete('token');
        localStorage.removeItem('token');
        store.dispatch('logout');
        router.push('/').catch(() => { });

        return Promise.reject(responseData);
      } else if (response.data.error) {
        return Promise.reject(responseData);
      } else {
        return responseData;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export const instanceImPay = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_IMPAY_URL,
    headers: {
      'Content-Type': 'application/json',
    }
  });

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export const instanceImERP = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_ERP_URL,
    headers: {
      'Content-Type': 'application/json',
    }
  });

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default { instanceImNic, instanceImPay, instanceImERP };