import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VuexPersistence from 'vuex-persist';
import authModule from './modules/auth';
import { instanceImPay, instanceImNic, instanceImERP } from '../config';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

function initialState() {
  return {
    franchisesCollaborator: [],
    franchiseCourses: [],
    isMultipleFranchise: false,
    hasFranchiseCourse: false,
    collaboratorId: ''
  };
}

const actions = {
  getFranchise({ commit }, data) {
    return new Promise((resolve, reject) => {
      instanceImNic().post('/multipleContract/franchisescollaborators', JSON.stringify(data))
        .then(response => {
          commit('setIsMultipleFranchise', response.items.length > 1);
          commit('setFranchisesCollaborator', response.items);
          commit('setCollaboratorId', response.items[0].IdColaborador);

          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCoursesByFranchise(_, data) {
    return new Promise((resolve, reject) => {
      instanceImNic().post('/multipleContract/coursesbyfranchise', JSON.stringify(data))
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCoursesDigital() {
    return new Promise((resolve, reject) => {
      instanceImNic().post('/multipleContract/digitalcourses')
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCourses(_, data) {
    return new Promise((resolve, reject) => {
      instanceImNic().post('/multipleContract/courses', JSON.stringify(data))
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getPersonByTaxpayerId(_, data) {
    return new Promise((resolve, reject) => {
      instanceImNic().post('/person/getbycpf', JSON.stringify(data))
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  postCreateContract(_, data) {
    return new Promise((resolve, reject) => {
      instanceImNic().post('/multipleContract/createcontract', JSON.stringify(data))
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getMedias() {
    return new Promise((resolve, reject) => {
      instanceImNic().get('/multipleContract/medias')
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  postCreateCheckout(_, contractId) {
    return new Promise((resolve, reject) => {
      instanceImERP().post(`/api/v1/contracts/${contractId}/create`)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateTermsOfAgreement(_, data) {
    return new Promise((resolve, reject) => {
      instanceImNic().post(`/pedagogico/updateTermsOfAgreement`, JSON.stringify(data))
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getContract(_, data) {
    return new Promise((resolve, reject) => {
      instanceImERP().get('/api/v1/contracts/' + data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getPostalCode(_, postalCode) {
    return new Promise((resolve, reject) => {
      instanceImPay({
        baseUrl: process.env.VUE_APP_BASE_API_IMPAY_URL,
        hasToken: false
      }).get(`/v1/postal-codes/${postalCode}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getDailyContracts(_, data) {
    return new Promise((resolve, reject) => {
      instanceImNic().post('/multipleContract/getdailycontracts', JSON.stringify(data))
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      commit('setLogOut');
      commit('authModule/setLogOut');
      resolve();
    });
  }
};

const getters = {
  getFranchisesCollaborator(state) {
    return state.franchisesCollaborator;
  },

  getIsMultipleFranchise(state) {
    return state.isMultipleFranchise;
  },

  getCollaboratorId(state) {
    return state.collaboratorId;
  }
};

const mutations = {
  setCollaboratorId(state, value) {
    state.collaboratorId = value;
  },

  setFranchisesCollaborator(state, items) {
    state.franchisesCollaborator = items.map(item => ({
      label: `${item.NomeFranquia} (${item.RegiaoFranquia})`,
      value: item.CodigoFranquia
    }));
  },

  setIsMultipleFranchise(state, status) {
    state.isMultipleFranchise = status;
  },

  setLogOut(state) {
    const inital = initialState();

    Object.keys(inital).forEach(key => {
      state[key] = inital[key];
    });
  }
};

const store = new Vuex.Store({
  state: initialState(),
  actions,
  getters,
  mutations,
  modules: {
    authModule: authModule,
  },
  plugins: [
    vuexLocal.plugin
  ]
});

export default store;