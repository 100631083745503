<template>
  <default-view-component
    :showAlertCard="showAlertCard"
    :alertCardData="alertCardData"
  >
    <template v-slot:content>
      <div class="content">
        <spinner-component
          v-if="hasRequest"
          class="spinner-component"
          :styles="spinner"
        />

        <div v-else class="term-container">
          <h1 class="title">TERMOS E CONDIÇÕES PARA AQUISIÇÃO E USO</h1>

          <div class="term-content">
            <div class="section">
              <p>
                O “Termo de Condições e Uso do <b>INSTITUTO MIX DE PROFISSÕES</b>”regula os direitos e obrigações relacionados à aquisição de cursos profissionalizantes no formato EAD, bem como o uso do site e da plataforma de aprendizagem. Este termo é celebrado entre o <b>INSTITUTO MIX DE PROFISSÕES</b> e Você, na qualidade de “Aluno”, “Usuário” ou “Visitante”, conforme os termos abaixo estabelecidos:
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">1. DA ACEITAÇÃO</h1>

              <p>
                O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, entre o <b>INSTITUTO MIX DE PROFISSÕES</b> e os usuários da plataforma ou do aplicativo.
              </p>

              <p>
                Ao utilizar a plataforma o usuário aceita integralmente as presentes normas e se compromete a observá-las, sob o risco de aplicação das penalidades cabíveis.
              </p>

              <p>
                A aceitação dos termos é fundamental para o acesso e para utilização de quaisquer serviços fornecidos. Caso não concorde com as disposições deste instrumento, o usuário não deve utilizá-los.
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">2. DO CADASTRO E ACESSO</h1>

              <p>
                O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, a depender dos cursos escolhidos, o pagamento de determinado valor.
              </p>

              <p>
                Ao se cadastrar o usuário deverá informar os dados solicitados, tais dados devem ser verdadeiros, completos, recentes e válidos, sendo de sua exclusiva responsabilidade manter os referidos dados atualizados.
              </p>

              <p>
                Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços educacionais disponíveis.
              </p>

              <p>
                Os relativamente incapazes (maiores de 16 e menores de 18 anos), que se cadastrem na qualidade de titular declaram que obtiveram o consentimento de seus pais ou responsáveis para este fim. O titular se responsabiliza por todo e qualquer uso da plataforma por seus eventuais dependentes.
              </p>

              <p>
                Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, onde lhe é assegurado o acesso individual a plataforma de ensino. Desta forma compete ao usuário, exclusivamente, a manutenção da referida de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.
              </p>

              <p>
                Fica vedado ao usuário, sob pena de imediato cancelamento da matrícula, sem direito à devolução de valores e sem prejuízo das demais medidas cabíveis no âmbito civil e criminal, efetuar as seguintes práticas:
              </p>

              <div class="sub">
                  <p>
                    a) Compartilhar seu login e senha com terceiros;
                  </p>

                  <p>
                    b) Adquirir o curso em regime de “group buy” ou outra forma de rateio onde várias pessoas adquirem o acesso através do nome de uma única pessoa compartilhando-o entre si;
                  </p>

                  <p>
                    c) Fazer uso de ferramentas para gravar o áudio dos vídeos ou para de qualquer forma fazer o download dos áudios não disponibilizados especificamente para esta finalidade, ainda que para uso exclusivo pessoal;
                  </p>

                  <p>
                    d) Fazer uso de ferramentas para fotografar a imagem da tela, inclusive prints de tela, ainda que para uso exclusivo pessoal;
                  </p>
              </div>

              <p>
                Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a plataforma em caso de uso indevido do respectivo acesso.
              </p>

              <p>
                Cabe ao usuário assegurar que o seu equipamento seja compatível com as características técnicas que viabilize a utilização da plataforma e dos serviços ou produtos. 
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">3. DOS SERVIÇOS EDUCACIONAIS</h1>

              <p>
                A plataforma disponibiliza para o usuário um conjunto específico de funcionalidades e ferramentas para otimizar o uso dos serviços educacionais dispostos.
              </p>

              <p>
                Na plataforma os cursos ofertados estão descritos e apresentados com exatidão, contendo informações sobre o conteúdo, carga horária, valores e toda informação necessária para a aquisição consciente do serviço educacional.
              </p>

              <p>
                Na contratação dos nossos serviços, nós solicitamos informações do usuário, como CPF, endereço de cobrança e dados de cartões. Ao inserir referidos dados o usuário concorda que sejam cobrados de acordo com a forma de pagamento que venha a ser escolhida, os preços então vigentes e informados quando da contratação.
              </p>

              <p>
                As aulas serão disponibilizadas no ambiente virtual de aprendizagem conforme plano do curso(s) escolhido(s), no formato da tecnologia de streaming, pela qual o usuário terá acesso à transmissão de dados do conteúdo das aulas diretamente no ambiente virtual da internet.
              </p>

              <p>
                As videoaulas serão disponibilizadas ao usuário com o acesso ao(s) curso(s) adquiridos(s) durante 24 horas por dia, 7 dias por semana, salvo por imprevistos ou motivos de força maior. O curso ficará disponível pelo período de 12 (doze) meses.
              </p>

              <p>
                Os cursos não contam com apostila inclusa no preço de venda, porém é opcional ao aluno, se desejar o material de forma impressa, adquirir em nossa editora.
              </p>

              <p>
                Para a emissão do certificado o usuário deverá obter a frequência mínima de 75% (setenta e cinco por cento) em cada módulo e média final maior ou igual a 7 (sete) pontos, no conjunto das avaliações realizadas em cada módulo (atividades ao final de cada módulo).
              </p>

              <p>
                Não existe a possibilidade de trancamento de matrícula, nem mudança de curso com o aproveitamento de créditos. Após o requerimento e cancelamento da matrícula não haverá o acesso às aulas, nem ao sistema da área de aluno. 
              </p>

              <p>
                O usuário assume total responsabilidade quanto às declarações e aceitação prestadas para a celebração do presente contrato eletrônico, especialmente com relação à sua identificação; dados cadastrais; assumir obrigações ora convencionadas; aptidão legal para cumprimento do curso, concordando, desde já, que a não entrega dos documentos legais comprobatórios das declarações prestadas, poderá acarretar o a extinção do deste contrato, isentando o IM de qualquer responsabilidade pelos eventuais danos resultantes do cancelamento.
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">4. DAS RESPONSABILIDADES</h1>

              <p>
                É de responsabilidade do usuário:
              </p>

              <div class="sub">
                <p>
                  a) Defeitos ou vícios técnicos originados no próprio sistema do usuário
                </p>

                <p>
                  b) A correta utilização da plataforma, dos serviços ofertados, prezando pela boa convivência, pelo respeito e cordialidade entre os usuários;
                </p>

                <p>
                  c) Pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Uso, na Política de Privacidade e nas legislações vigentes;
                </p>

                <p>
                  d) Pela proteção aos dados de acesso à sua conta (login e senha);
                </p>

                <p>
                  e) As informações prestadas;
                </p>
              </div>

              <p>
                É de responsabilidade da plataforma:
              </p>

              <div class="sub">
                <p>
                  a) Indicar as características dos serviços ofertados;
                </p>

                <p>
                  b) Defeitos e Vícios encontrados nos serviços ofertados, desde que tenha lhe dado causa;
                </p>

                <p>
                  c) Conteúdos e atividades lícitas originadas da plataforma;
                </p>
              </div>

              <p>
                A plataforma não se responsabiliza por links externos contidos em seu sistema que possam redirecionar o usuário à ambiente externo a sua rede.
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">5. DA PROTEÇÃO DE DADOS</h1>

              <p>
                O usuário por livre manifestação de vontade, consente e concorda, que o <b>INSTITUTO MIX DE PROFISSÕES</b> tenha acesso aos dados coletados no momento do cadastro, conforme dispõe a LGPD – Lei Geral de Proteção de Dados, sendo:
              </p>

              <table border="1">
                <thead>
                    <tr>
                        <th>NATUREZA DOS DADOS</th>
                        <th>DADOS PESSOAIS</th>
                        <th>FINALIDADE DO USO</th>
                        <th>BASE LEGAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="5">Cadastrais</td>
                        <td rowspan="5">Nome completo, CPF, Data de Nascimento, Endereço completo, E-mail, Telefone</td>
                        <td>Prestação dos serviços educacionais e gestão administrativa</td>
                        <td>Execução de Contrato</td>
                    </tr>
                    <tr>
                        <td>Oferta de cursos e serviços</td>
                        <td>Consentimento</td>
                    </tr>
                    <tr>
                        <td>Cumprir com possíveis exigências legais, se necessário</td>
                        <td>Cumprimento de Obrigação legal ou regulatória</td>
                    </tr>
                    <tr>
                        <td>Procedimentos financeiros</td>
                        <td>Proteção ao crédito</td>
                    </tr>
                    <tr>
                        <td>Armazenamento pelo período prescricional</td>
                        <td>Exercício regular do direito</td>
                    </tr>
                </tbody>
              </table>

              <p>
                O usuário autoriza o compartilhamento dos seus dados pessoais com agentes de tratamento de dados internos, caso seja necessário, para as finalidades listadas e gestão administrativa, observado os princípios e as garantias estabelecidas pela LGPD.
              </p>

              <p>
                O usuário e o INSTITUTO MIX devem tomar as medidas cabíveis e aplicar as medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados ou de qualquer forma de tratamento inadequado ou ilícito, com o objetivo de assegurar o nível de segurança devido aos dados pessoais decorrentes deste CONTRATO.
              </p>

              <p>
                Os dados pessoais do usuário serão armazenados por todo o período contratualmente firmado para as finalidades relacionadas nesse instrumento e ainda após o término da contratação para cumprimento de obrigação legal ou impostas por órgãos de fiscalização, nos termos do artigo 16 da Lei n° 13.709/2018.
              </p>

              <p>
                O usuário pode entender melhor como realizamos o tratamento dos seus dados pessoais acessando a nossa política de privacidade em: <a href="https://institutomix.com.br/privacidade/">https://institutomix.com.br/privacidade/</a> e <a href="https://loja.institutomix.com.br/politicas-de-privacidade">https://loja.institutomix.com.br/politicas-de-privacidade</a> e pode entrar em contato para maiores esclarecimentos sobre os seus dados pessoais, retificação, informações e revogação de consentimento no e-mail do nosso encarregado de dados: encarregadodedados@institutomix.com.br
              </p>

              <p>
                Qualquer controvérsia ou incidente envolvendo dados pessoais deverá ser solucionada através de conciliação direta entre a plataforma e o usuário, a ser realizada de modo físico ou online junto ao encarregado de dados da instituição.
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">6. DA PROPRIEDADE INTELECTUAL</h1>

              <p>
                O presente Termo em conjunto coma  devida contratação concede aos usuários uma licença não exclusiva, não transferível e não sublicenciável, para acessa e fazer da plataforma e dos serviços educacionais por ela disponibilizados.
              </p>

              <p>
                A estrutura do site ou aplicativo, as marcas, logotipo, nomes comerciais, gráfico e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e áudio, programas de computador, banco dedados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual do <b>INSTITUTO MIX DE PROFISSÕES</b>, observados os termos da legislação aplicável estão devidamente reservados.
              </p>

              <p>
                Este Termo de condições e Uso não cede ou transfere ao usuário qualquer direito, de modo que o acesso não gera qualquer direito de propriedade intelectual ao usuário, exceto pela licença limitada ora concedida.
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">7. DAS SANÇÕES</h1>

              <p>
                Sem prejuízo das demais medidas legais cabíveis, o <b>INSTITUTO MIX DE PROFISSÕES </b>poderá a qualquer momento, advertir, suspender ou cancelar a conta do usuário:
              </p>

              <div class="sub">
                <p>
                  a) Que violar qualquer dispositivo do presente Termo;
                </p>

                <p>
                  b) Que descumprir os seus deveres de usuário;
                </p>

                <p>
                  c) Que tiver qualquer comportamento fraudulento, doloso ou que ofenda a terceiros;
                </p>
              </div>
            </div>

            <div class="section">
              <h1 class="subtitle">8. DA RESCISÃO</h1>

              <p>
                A não observância das obrigações pactuada neste Termo de Condições de Uso ou da Legislação aplicável poderá, sem aviso prévio, ensejar a imediata rescisão unilateral por parte do <b>INSTITUTO MIX DE PROFISSÕES</b> e o bloqueio de todos os serviços prestados ao usuário.
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">9. DAS ALTERAÇÕES</h1>

              <p>
                Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a qualquer tempo, por parte do <b>INSTITUTO MIX DE PROFISSÕES</b>, para adequar ou modificar os serviços ofertados, bem como para atender novas exigências legais. Sem prejuízo daqueles que já utilizam algum serviço.
              </p>
            </div>

            <div class="section">
              <h1 class="subtitle">10. DO FORO</h1>

              <p>
                Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.
              </p>

              <p>
                Os eventuais litígios deverão ser apresentados na plataforma oficial consumidor.gov.br, como mecanismo prioritário para dirimir dúvidas e conflitos do presente contrato, e, em não havendo solução por qualquer das partes, elegem, então, o foro da comarca de Araranguá/SC como o competente para a solução dos demais litígios remanescentes.
              </p>

              <p>
                Última atualização em 05.08.2024
              </p>
            </div>
          </div>

          <div class="button-container">
            <button-component
              class="button-component"
              v-ripple="'rgba(255, 255, 255, 0.2)'"
              buttonTextColor="#FFFFFF"
              buttonHoverColor="rgba(255, 255, 255, 0.1)"
              text="Li e aceito os termos"
              :hasLoader="hasCreateCheckoutRequest"
              :isDisabled="hasCreateCheckoutRequest"
              @click.native="submit"
            />
          </div>
        </div>
      </div>
    </template>
  </default-view-component>
</template>

<script>
  import DefaultView from '@/components/base/DefaultView';
  import Spinner from '@/components/Spinner';
  import Button from '@/components/Button';

  export default {
    name: 'AcceptTermsPage',

    data() {
      return {
        hasRequest: false,
        hasCreateCheckoutRequest: false,

        showAlertCard: false,
        alertCardData: {},

        contractId: '',

        spinner: {
          size: '4px',
          color: '#FEAA3B',
          borderSize: '1em',
        },
      };
    },

    components: {
      'default-view-component': DefaultView,
      'spinner-component': Spinner,
      'button-component': Button
    },

    created() {
      if (this.$route.params.contractId) {
        this.contractId = this.$route.params.contractId;
        this.fetchContract(this.contractId);
      }
    },

    methods: {
      async fetchContract(contractId) {
        this.hasRequest = true;

        await this.$store.dispatch('getContract', contractId)
          .then(res => {
            if (res.data.is_checkout_fd == "0") {
              this.toContractAction();
            }

            if (res.data.is_agreement_accepted == "1" && !res.data.id_checkout) {
              this.createCheckout(contractId);
            }

            if (res.data.id_checkout) {
              this.toCheckout();
            }
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao buscar contrato',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;

            this.$router.push('/precontract/new');
          });

        this.hasRequest = false;
      },

      async createCheckout(contractId) {
        await this.$store.dispatch('postCreateCheckout', contractId)
          .then(() => {
            this.toCheckout();
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao criar link de pagamento',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      async acceptAgreement() {
        const data = {
          contract_id: this.contractId,
        };

        await this.$store.dispatch('updateTermsOfAgreement', data)
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao aceitar os termos',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      toCheckout() {
        this.$router.push(`/precontract/${this.contractId}/checkout/pay`);
      },

      toContractAction() {
        this.$router.push(`/precontract/${this.contractId}`);
      },

      async submit() {
        this.hasCreateCheckoutRequest = true;

        await this.acceptAgreement();
        await this.createCheckout(this.contractId);

        this.hasCreateCheckoutRequest = false;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .spinner-component {
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    .term-container {
      max-width: 768px;
      padding: 1rem;
      width: 100%;

      .title {
        font-size: 20px;
        margin-bottom: 0.5rem;
        text-align: center;
      }

      .term-content {
        overflow-y: auto;
        height: 55vh;

        .section {
          margin-top: 1rem;
          padding: 0 1rem;

          .sub {
            margin-left: 1.5rem;
          }

          .subtitle {
            font-size: 16px;
            margin-bottom: 0.5rem;
          }

          a {
            color: #3182CE;
          }

          p {
            text-align: justify;
            font-size: 12px;
            margin-bottom: 0.5rem;
          }

          table {
            width: 100%;
            font-size: 10px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            border-collapse: collapse;
          }

          thead th {
            background-color: #D9D9D9;
            padding: 8px;
            text-align: left;
          }

          tbody td {
            padding: 8px;
            border: 1px solid #000;
          }
        }
      }

      .button-container {
        margin-top: 1.5rem;

        .button-component {
          max-width: 200px;
          margin-left: auto;
        }
      }
    }
  }
</style>