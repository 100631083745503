<template>
  <default-view-component>
    <template v-slot:content>
      <div class="content">
        <spinner-component
          v-if="hasRequest"
          class="spinner-component"
          :styles="spinner"
        />

        <default-section-component v-else class="default-section-component">
          <template v-slot:content>
            <div class="section-content">
              <div class="icon-container">
                <i :class="cardData.iconName ? cardData.iconName : 'icon-error-circle'"/>
              </div>

              <h1 v-if="!!cardData.message">
                {{ cardData.message}}
              </h1>

              <p v-if="!!contractId && !!courseName">
                Contrato <span>{{ contractId }}</span> - Curso <span>{{ courseName }}</span>
              </p>

              <p v-if="!!franchiseName">
                <span>{{ franchiseName }}</span>
              </p>

              <icon-button-component
                v-if="hasRedirectButton"
                class="button-component"
                :iconName="iconButtonData.iconName"
                :text="iconButtonData.text"
                @click.native="toAction"
              />
            </div>
           </template>
        </default-section-component>
      </div>
    </template>
  </default-view-component>
</template>

<script>
  import DefaultView from '@/components/base/DefaultView';
  import DefaultSection from '@/components/base/DefaultSection';
  import IconButton from '@/components/IconButton';
  import Spinner from '@/components/Spinner';

  export default {
    name: 'ContractActionPage',

    components: {
      'default-view-component': DefaultView,
      'default-section-component': DefaultSection,
      'icon-button-component': IconButton,
      'spinner-component': Spinner
    },

    data() {
      return {
        hasRequest: false,
        contractId: '',
        courseName: '',
        franchiseName: '',
        hasCheckoutId: false,

        cardData: {
          iconName: '',
          message: ''
        },

        spinner: {
          size: '4px',
          color: '#FEAA3B',
          borderSize: '1em',
        },

        hasRedirectButton: false,
        iconButtonData: {
          iconName: '',
          text: ''
        }
      };
    },

    created() {
      if (this.$route.params.contractId) {
        this.contractId = this.$route.params.contractId;
        this.fetchContract(this.contractId);
      } else {
        this.$router.push('/precontract/new');
      }
    },

    methods: {
      async fetchContract(contractId) {
        this.hasRequest = true;

        await this.$store.dispatch('getContract', contractId)
          .then(res => {
            this.courseName = res.data.Descricao;
            this.franchiseName = res.data.NomeFranquia;
            this.hasCheckoutId = !!res.data.id_checkout;

            if (res.data.is_checkout_fd == "1") {
              this.toAcceptTerms();
            }

            if (!this.hasCheckoutId && this.$service.toUpperCase(res.data.StatusDocumento) == 'PENDENTE' || this.$service.toUpperCase(res.data.StatusDocumento) == 'PENDING') {
              this.iconButtonData.iconName = 'icon-file';
              this.iconButtonData.text = 'Assinar';
            } else {
              this.iconButtonData.iconName = 'icon-link';
              this.iconButtonData.text = 'Pagamento';
            }

            this.cardData = {
              iconName: 'icon-warning',
              message: 'Ops... algo de errado aconteceu'
            };

            this.hasRedirectButton = true;
          })
          .catch(() => {
            this.cardData = {
              iconName: 'icon-error-circle',
              message: `Contrato ${this.contractId} não encontrado.`
            };
          });

        this.hasRequest = false;
      },

      toAcceptTerms() {
        this.$router.push(`/precontract/${this.contractId}/accept/terms`);
      },

      toAction() {
        this.$router.push(`/precontract/${this.contractId}/contract/sign`);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;

    .spinner-component {
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    .default-section-component {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      max-width: 672px;
      width: 100%;

      .section-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .icon-container {
          margin-bottom: 1rem;

          i {
            font-size: 4rem;

            &.icon-warning {
              color: #FF9C00;
            }

            &.icon-error-circle {
              color: #b81d5b;
            }
          }
        }

        p {
          margin-top: 0.2rem;
        }

        span  {
          font-weight: 700;
        }

        .button-component {
          margin-top: 1rem;
        }
      }
    }
  }

  @media (max-width: 720px) {
    .content {
      padding: 0 1.5rem;

      .default-section-component  {
        width: 100%;
      }
    }
  }
</style>