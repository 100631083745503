<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    mounted() {
      if (process.env.NODE_ENV !== 'production') console.log(`Environment: %c${process.env.NODE_ENV}`, 'color: red;');
    }
  };
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    letter-spacing: 0.2px;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    height: 100%;
  }

  body, input, select, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-style: normal;
  }

  body {
    min-width: 320px;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
  }

  #app {
    height: 100%;
  }

  button {
    line-height: 100%;
    font-weight: 600;
    font-size: 17px;
  }

  img {
    -webkit-user-drag: none;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-weight: 300;
    font-size: 14px;
  }
</style>