<template>
  <default-view-component
    :showAlertCard="showAlertCard"
    :alertCardData="alertCardData"
    @hideAlertCard="showAlertCard = !showAlertCard"
  >
    <template v-slot:content>
      <div class="container">
        <div v-if="!isSummary" class="content">
          <ValidationObserver
            class="form-container"
            tag="div"
            ref="form"
            v-slot="{ passes }"
          >
            <form @submit.prevent="passes(submit)">
              <default-section-component class="default-section-component" title="Informações do contrato">
                <template v-slot:content>
                  <div class="field-container">
                    <div class="section">
                      <div class="row">
                        <input-field
                          class="input-field large"
                          label="CPF"
                          placeholder="Ex. 000.000.000-00"
                          mask="###.###.###-##"
                          :hasButtonCleaner="true"
                          rules="required|cpf"
                          :isDisabled="hasRequest"
                          v-model="data.taxpayerId"
                        />

                        <input-field
                          class="input-field x-large"
                          label="Nome completo"
                          placeholder="Ex. José Martins"
                          :hasButtonCleaner="true"
                          rules="required|completedName"
                          :isDisabled="hasRequest || disableNameField"
                          v-model="data.name"
                        />
                      </div>

                      <div class="row">
                        <input-field
                          class="input-field x-large"
                          label="E-mail"
                          placeholder="Ex. joao.santos@mail.com"
                          :hasButtonCleaner="true"
                          rules="required|email"
                          :isDisabled="hasRequest || disableEmailField"
                          v-model="data.email"
                        />

                        <input-field
                          class="input-field large"
                          label="Telefone"
                          placeholder="Ex. (48) 99999-9999"
                          :mask="['(##) #####-####', '(##) ####-####']"
                          :hasButtonCleaner="true"
                          rules="required|phone"
                          :isDisabled="hasRequest || disablePhoneField"
                          v-model="data.phone"
                        />
                      </div>

                      <div class="row">
                        <input-field
                          class="input-field medium"
                          ref="postalCode"
                          label="CEP"
                          placeholder="Ex. 88900-000"
                          mask="#####-###"
                          rules="postalCode"
                          :hasButtonCleaner="true"
                          :isDisabled="hasRequest"
                          v-model="data.postalCode"
                        />
                      </div>
                    </div>

                    <div class="section">
                      <checkbox-component v-model="data.isResponsible" label="Não é aluno" :isDisabled="hasRequest"/>

                      <div v-if="data.isResponsible" class="row">
                        <input-field
                          class="input-field large"
                          label="CPF"
                          placeholder="Ex. 000.000.000-00"
                          mask="###.###.###-##"
                          :hasButtonCleaner="true"
                          rules="required|cpf"
                          :isDisabled="hasRequest"
                          v-model="data.student.taxpayerId"
                        />

                        <input-field
                          class="input-field x-large"
                          label="Nome completo do aluno"
                          placeholder="Ex. João Martins"
                          :hasButtonCleaner="true"
                          rules="required|completedName"
                          :isDisabled="hasRequest || disableStudentNameField"
                          v-model="data.student.name"
                        />
                      </div>
                    </div>

                    <div class="section">
                      <p>Matrícula / Entrada / Sinal</p>

                      <div class="row">
                        <input-field
                          class="input-field small"
                          label="Valor"
                          placeholder="R$ 200,00"
                          :maxLength="11"
                          type="money"
                          :rules ="mountInputMoneyRules('minValue', data.amountEnrollment, 0)"
                          :hasButtonCleaner="true"
                          :isDisabled="hasRequest"
                          v-model="data.amountEnrollment"
                        />

                        <input-field
                          class="input-field small"
                          label="Data vencimento"
                          type="date"
                          placeholder="Ex. 17/10/2022"
                          :hasButtonCleaner="true"
                          rules="required|date"
                          :isDisabled="hasRequest"
                          v-model="data.dueDate"
                        />
                      </div>
                    </div>

                    <div class="section">
                      <checkbox-component v-model="data.hasSignal" label="Complemento de matrícula" :isDisabled="hasRequest"/>

                      <div v-if="data.hasSignal" class="row">
                        <input-field
                          class="input-field small"
                          label="Valor"
                          placeholder="R$ 100,00"
                          :maxLength="11"
                          type="money"
                          :rules="mountInputMoneyRules('maxValue', data.amountEnrollment, data.signal.amount)"
                          :hasButtonCleaner="true"
                          :isDisabled="hasRequest"
                          v-model="data.signal.amount"
                        />

                        <input-field
                          class="input-field small"
                          label="Data vencimento"
                          type="date"
                          placeholder="Ex. 15/10/2022"
                          :hasButtonCleaner="true"
                          rules="required|date"
                          :isDisabled="hasRequest"
                          v-model="data.signal.dueDate"
                        />
                      </div>
                    </div>

                    <div class="section">
                      <p>Curso(s)</p>

                      <div class="row">
                        <select-field
                          v-if="getIsMultipleFranchise"
                          class="input-field x-large"
                          label="Franquia"
                          rules="required"
                          :option="getFranchisesCollaborator"
                          v-model="data.franchiseId"
                          :isDisabled="hasRequest"
                        />

                        <input-field
                          v-else
                          class="input-field x-large"
                          label="Franquia"
                          rules="required"
                          placeholder="Selecione"
                          :value="getFranchiseLabel(data.franchiseId)"
                          :isDisabled="true"
                        />
                      </div>

                      <div v-if="showModality" class="row">
                        <div class="toggle-card">
                          <div
                            v-for="(item, index) in modalityCardData"
                            :key="index"
                            class="card"
                            :class="{ 'active': item.isActive, 'disable': item.isDisabled || hasRequest }"
                            @click="toggleModality(item.value)"
                          >
                            {{ item.label }}
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <select-field
                          class="input-field x-large"
                          label="Mídia"
                          :option="medias"
                          v-model="data.mediaId"
                          :isDisabled="hasRequest || !data.franchiseId || medias.length == 0"
                        />
                      </div>

                      <div v-for="(item, index) in data.courses" :key="`${item.id}-${index}`" class="row action">
                        <div class="row-content" :class="{ 'full': !isDigital() }">
                          <select-field
                            v-if="!isDigital()"
                            class="input-field select"
                            :class="!isDigital() ? 'x-large' : 'large'"
                            label="Curso"
                            rules="required"
                            :option="franchiseGenericCourses"
                            :isDisabled="!(franchiseGenericCourses.length > 0) || hasRequest"
                            v-model="item.genericId"
                            @input="formatFranchiseCourses(item)"
                          />

                          <select-field
                            class="input-field select"
                            :class="!isDigital() ? 'x-large' : 'large'"
                            :label="isDigital() ? 'Curso' : 'Versão'"
                            rules="required"
                            :value="item.id"
                            :option="isDigital() ? franchiseDigitalCourses : item.franchiseNormalCourses"
                            :isDisabled="!(franchiseDigitalCourses.length > 0) && !(item.franchiseNormalCourses.length > 0) || hasRequest"
                            v-model="item.id"
                          />
                        </div>

                        <div v-if="index != 0" class="action-container">
                          <i class="icon-remove" @click="removeCourse(index)" />
                        </div>
                      </div>

                      <button-component
                        v-if="data.courses.length < 3"
                        text="Adicionar"
                        styles="ghost"
                        v-ripple="'rgba(255, 139, 0, 0.2)'"
                        icon-left="icon-add"
                        type="button"
                        :style="{ 'margin-bottom': '2.5rem', 'max-width': '150px' }"
                        :hasLoader="hasRequest"
                        :isDisabled="hasCourseIdEmpty() || hasRequest"
                        @click.native="addCourse"
                      />

                      <div v-if="!isDigital()" class="row">
                        <div class="items">
                          <input-field
                            class="input-field small"
                            label="Data início"
                            type="date"
                            placeholder="Ex. 13/11/2022"
                            :hasButtonCleaner="true"
                            :isDisabled="!hasSomeCourseIdSelected() || hasRequest"
                            rules="required|date"
                            v-model="data.startDate"
                          />

                          <input-field
                            class="input-field small"
                            ref="endDateField"
                            label="Data fim"
                            type="date"
                            placeholder="Ex. 27/12/2022"
                            :minCalendarDate="data.startDate"
                            :hasButtonCleaner="true"
                            :isDisabled="disableEndDate || hasRequest"
                            rules="required|date"
                            v-model="data.endDate"
                          />
                        </div>

                        <div class="items">
                          <select-field
                            class="input-field time"
                            label="Horário início"
                            rules="required"
                            :option="startTimesArr"
                            :isDisabled="disableStartTime || hasRequest"
                            v-model="data.startTime"
                          />

                          <select-field
                            class="input-field time"
                            label="Horário fim"
                            rules="required"
                            :option="endTimesArr"
                            :isDisabled="disableEndTime || hasRequest"
                            v-model="data.endTime"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="section">
                      <p>Parcelas</p>

                      <div class="row" :style="{ 'justify-content': 'space-between' }">
                        <div class="items">
                          <input-field
                            class="input-field small"
                            label="Valor"
                            placeholder="Ex. R$ 700,00"
                            :maxLength="11"
                            type="money"
                            rules="required"
                            :hasButtonCleaner="true"
                            :isDisabled="hasRequest"
                            v-model="data.payment.amount"
                          />

                          <input-field
                            class="input-field small"
                            ref="courseDiscountField"
                            label="Valor c/ desc."
                            placeholder="Ex. R$ 670,00"
                            :maxLength="11"
                            type="money"
                            :rules ="mountInputMoneyRules('maxValue', data.payment.amount, data.payment.amountWithDiscount)"
                            :hasButtonCleaner="true"
                            :isDisabled="hasRequest || disableCourseDiscountAmountField"
                            v-model="data.payment.amountWithDiscount"
                          />
                        </div>

                        <input-field
                          class="input-field small"
                          label="Data vencimento"
                          type="date"
                          placeholder="Ex. 19/06/2022"
                          :hasButtonCleaner="true"
                          rules="required|date"
                          :isDisabled="hasRequest"
                          v-model="data.payment.dueDate"
                        />
                      </div>

                      <div class="row">
                        <range-field
                          class="range-field"
                          label="Quantidade de parcelas"
                          :secondLabel="data.payment.amountWithDiscount ? totalCourseAmount : ''"
                          :min="1"
                          :max="30"
                          :initialValue="data.payment.installments"
                          :isDisabled="hasRequest || disableCourseInstallment"
                          v-model="data.payment.installments"
                        />
                      </div>
                    </div>

                    <div class="section">
                      <checkbox-component label="Material didático" v-model="data.isCourseware" :isDisabled="hasRequest"/>

                      <div v-if="data.isCourseware" >
                        <div class="row" :style="{ 'justify-content': 'space-between' }">
                          <div class="items">
                            <input-field
                              class="input-field small"
                              label="Valor"
                              placeholder="Ex. R$ 700,00"
                              :maxLength="11"
                              type="money"
                              rules="required"
                              :hasButtonCleaner="true"
                              :isDisabled="hasRequest"
                              v-model="data.courseware.amount"
                            />

                            <input-field
                              class="input-field small"
                              ref="coursewareDiscountField"
                              label="Valor c/ desc."
                              placeholder="Ex. R$ 670,00"
                              :maxLength="11"
                              type="money"
                              :rules="mountInputMoneyRules('maxValue', data.courseware.amount, data.courseware.amountWithDiscount)"
                              :hasButtonCleaner="true"
                              :isDisabled="hasRequest || disableCoursewareDiscountAmountField"
                              v-model="data.courseware.amountWithDiscount"
                            />
                          </div>

                          <input-field
                            class="input-field small"
                            label="Data vencimento"
                            type="date"
                            placeholder="Ex. 19/06/2022"
                            :hasButtonCleaner="true"
                            rules="required|date"
                            :isDisabled="hasRequest"
                            v-model="data.courseware.dueDate"
                          />
                        </div>

                        <div class="row">
                          <range-field
                            class="range-field"
                            label="Quantidade de parcelas"
                            :secondLabel="data.courseware.amountWithDiscount ? totalCoursewareAmount : ''"
                            :min="1"
                            :max="30"
                            :initialValue="data.courseware.installments"
                            :isDisabled="hasRequest || disableCoursewareInstallment"
                            v-model="data.courseware.installments"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </default-section-component>

              <div class="button-container">
                <button-component
                  class="button-component left"
                  text="Próximo"
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  :hasLoader="hasRequest"
                  :isDisabled="hasRequest"
                />
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div v-else class="content">
          <default-section-component class="default-section-component" title="Resumo do contrato">
            <template v-slot:content>
              <div class="summary-container">
                <div class="summary-section">
                  <div class="item">
                    <p class="label">
                      Aluno
                    </p>

                    <p class="value">
                      {{ data.isResponsible ? `${data.student.name + ', CPF ' + data.student.taxpayerId}` : `${data.name + ', CPF ' + data.taxpayerId}` }}
                    </p>
                  </div>
                </div>

                <div class="summary-section">
                  <div class="item">
                    <p class="label">
                      Modalidade
                    </p>

                    <p class="value">
                      {{ isDigital() ? 'Digital' : 'Presencial' }}
                    </p>
                  </div>
                </div>

                <div class="summary-section">
                  <div class="item">
                    <p class="label">
                      Mídia
                    </p>

                    <p class="value">
                      {{ getMidiaLabel(data.mediaId) }}
                    </p>
                  </div>
                </div>


                <div class="summary-section">
                  <div v-for="(item, index) in getSelectedCoursesDetail()" :key="index" class="item">
                    <p v-if="index == 0" class="label">
                      Curso(s)
                    </p>

                    <p class="value">
                      {{ item.label }}
                    </p>
                  </div>

                  <div v-if="!isDigital()" class="item">
                    <p class="label">
                      Duração
                    </p>

                    <p class="value">
                      {{ $service.dateFormat(data.startDate, 'DD/MM/YYYY') }} - {{ $service.dateFormat(data.endDate, 'DD/MM/YYYY') }}
                    </p>
                  </div>

                  <div v-if="!isDigital()" class="item">
                    <p class="label">
                      Horários
                    </p>

                    <p class="value">
                      {{ parseTime(data.startTime) }} - {{ parseTime(data.endTime) }}
                    </p>
                  </div>
                </div>

                <div class="summary-section">
                  <div class="item">
                    <p class="label">
                      Matrícula / Entrada / Sinal
                    </p>

                    <p class="value">
                      {{ $service.formatBrlAmountSymbol(data.amountEnrollment) }}
                    </p>
                  </div>

                  <div v-if="data.hasSignal" class="item">
                    <p class="label">
                      Complemento de matrícula
                    </p>

                    <p class="value">
                      {{ $service.formatBrlAmountSymbol(data.signal.amount) }}
                    </p>
                  </div>

                  <div v-if="data.isCourseware" class="item">
                    <p class="label">
                      Material didático
                    </p>

                    <p class="value">
                      {{ `${data.courseware.installments  + 'x de ' + $service.formatBrlAmountSymbol(data.courseware.amountWithDiscount)}` }}
                    </p>
                  </div>

                  <div class="item">
                    <p class="label">
                      Parcela
                    </p>

                    <p class="value">
                      {{ `${data.payment.installments  + 'x de ' + $service.formatBrlAmountSymbol(data.payment.amountWithDiscount)}` }}
                    </p>
                  </div>

                  <div class="divider"></div>

                  <div class="item">
                    <p class="label">
                      Total do contrato
                    </p>

                    <p class="value">
                    {{ $service.formatBrlAmountSymbol(parseTotalCourseAmount()) }}
                    </p>
                  </div>

                  <div class="item">
                    <p class="label">
                      Saldo a pagar
                    </p>

                    <p class="value">
                      {{ $service.formatBrlAmountSymbol(parseDebitCourseAmount()) }}
                    </p>
                  </div>
                </div>

                <div class="summary-section">
                  <div class="item action">
                    <p class="label">
                      Pagar contrato à vista ?
                    </p>

                    <toggle-switch
                      class="toggle-switch-component"
                      :isDisabled="hasRequest"
                      v-model="data.isTotalPayment"
                    />
                  </div>
                </div>
              </div>
            </template>
          </default-section-component>

          <div class="button-container">
            <button-component
              text="Voltar"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              styles="secondary"
              iconLeft="icon-arrow-left"
              :isDisabled="hasRequest"
              @click.native="isSummary = !isSummary"
            />

            <button-component
              text="Finalizar"
              class="button-component left"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              :hasLoader="hasRequest"
              :isDisabled="hasRequest"
              @click.native="createCheckout()"
            />
          </div>
        </div>
      </div>
    </template>
  </default-view-component>
</template>

<script>
  import 'moment/locale/pt-br';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import { ValidationObserver } from 'vee-validate';
  import InputField from '@/components/InputField';
  import DefaultSection from '@/components/base/DefaultSection';
  import DefaultView from '@/components/base/DefaultView';
  import Button from '@/components/Button';
  import SelectField from '@/components/SelectField';
  import Checkbox from '@/components/Checkbox';
  import RangeField from '@/components/RangeField';
  import ToggleSwitch from '@/components/ToggleSwitch';

  export default {
    name: 'NewContractPage',

    data() {
      return {
        hasRequest: false,
        hasPostalCodeRequest: false,
        hasFranchiseSelected: false,

        isSummary: false,

        showAlertCard: false,
        alertCardData: {},

        showModality: false,

        modalityCardData: [
          {
            label: 'Presencial',
            isActive: true,
            isDisabled: false,
            value: 'normal'
          },

          {
            label: 'IM Digital',
            isActive: false,
            isDisabled: false,
            value: 'digital'
          }
        ],

        franchiseCourseDay: [
          {
            label: 'Domingo',
            value: 0
          },

          {
            label: 'Segunda-feira',
            value: 1
          },

          {
            label: 'Terça-feira',
            value: 2
          },

          {
            label: 'Quarta-feira',
            value: 3
          },

          {
            label: 'Quinta-feira',
            value: 4
          },

          {
            label: 'Sexta-feira',
            value: 5
          },

          {
            label: 'Sábado',
            value: 6
          }
        ],

        data: {
          name: '',
          phone: '',
          email: '',
          taxpayerId: '',

          amountEnrollment: '',
          dueDate: '',

          postalCode: '',
          address: this.initialAddress(),

          franchiseId: '',

          mediaId: '',

          isDigital: false,

          startWeekday: '',
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: '',

          courses: [
            {
              genericId: '',
              id: '',
              franchiseNormalCourses: []
            }
          ],

          isTotalPayment: false,
          payment: {
            amount: '',
            amountWithDiscount: '',
            dueDate: '',
            installments: 1
          },

          isResponsible: false,
          student: {
            taxpayerId: '',
            name: ''
          },

          hasSignal: false,
          signal: {
            amount: '',
            dueDate: '',
          },

          isCourseware: false,
          courseware: {
            amount: '',
            amountWithDiscount: '',
            dueDate: '',
            installments: 1
          }
        },

        franchiseDigitalCourses: [],
        franchiseGenericCourses: [],

        medias: [],

        disableWeekday: true,

        disableStartDate: true,
        disableEndDate: true,

        startTimesArr: [],
        disableStartTime: true,

        endTimesArr: [],
        disableEndTime: true,

        disableCourseInstallment: true,
        disableCoursewareInstallment: true,
        disableCourseDiscountAmountField: true,
        disableCoursewareDiscountAmountField: true,

        disableEmailField: true,
        disableNameField: true,
        disablePhoneField: true,

        disableStudentNameField: true,

        totalCourseAmount: '',
        totalCoursewareAmount: '',

        referenceId: '',
      };
    },

    computed: {
      ...mapGetters({
        getIsMultipleFranchise: 'getIsMultipleFranchise',
        getFranchisesCollaborator: 'getFranchisesCollaborator',
        getCollaboratorId: 'getCollaboratorId',
        getIsExternalAuth: 'authModule/getIsExternalAuth'
      })
    },

    components: {
      ValidationObserver,
      'input-field': InputField,
      'select-field': SelectField,
      'default-section-component': DefaultSection,
      'default-view-component': DefaultView,
      'checkbox-component': Checkbox,
      'button-component': Button,
      'range-field': RangeField,
      'toggle-switch': ToggleSwitch
    },

    created() {
      this.referenceId = this.$route.params.referenceId;

      this.data.isResponsible = this.$service.stringToBoolean(this.$route.params?.isResponsible);
      this.data.student.name = this.$route.params.studentName;
      this.data.student.taxpayerId = this.$route.params.studentTaxpayerId;

      this.data.postalCode = this.$route.params.postalCode;
      this.data.taxpayerId = this.$route.params.taxpayerId;

      if (this.$route.params?.franchiseId) {
        var items = this.getFranchisesCollaborator.filter(item => item.value == this.$route.params.franchiseId);

        this.data.franchiseId = items.length == 1 ? this.$route.params.franchiseId : '';
      }

      if (this.getIsExternalAuth && !this.$route.params?.fistExternalLogin) {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push(this.$router.history._startLocation);
          });
      }

      this.disableToggleCards();
    },

    mounted() {
      if (!this.getIsMultipleFranchise && this.getFranchisesCollaborator.length > 0) {
        this.data.franchiseId = this.getFranchisesCollaborator[0].value;
      }

      this.fetchMedias();
    },

    watch: {
      'data.postalCode'(value) {
        if (value && this.$service.removeSpecialCharacter(value).length == 8) {
          this.fetchPostalCode();
        }
      },

      'data.taxpayerId'(value) {
        if (this.hasTaxpayerUserFetch(value)) {
          this.$store.dispatch('getPersonByTaxpayerId', {
            document: value
          })
            .then(res => {
              this.data.name = res.person.NomePessoaNomeFantasia ?? '';
              this.data.phone = this.$service.removeSpecialCharacter(res.person.TelefoneCelular);
              this.data.email = res.person.Email ?? '';
            })
            .catch(() => {
              this.resetResponsibleData();

              this.data.name = this.$route.params?.name ?? '';
              this.data.phone = this.$route.params?.phone ?? '';
              this.data.email = this.$route.params?.email ?? '';

              this.disableNameField = false;
            })
            .finally(() => {
              this.disableEmailField = false;
              this.disablePhoneField = false;
            });
        } else {
            this.resetResponsibleData();

            this.disableNameField = true;
            this.disableEmailField = true;
            this.disablePhoneField = true;
        }
      },

      'data.isDigital'() {
        this.franchiseDigitalCourses = [];
        this.franchiseGenericCourses = [];

        this.data.courses = [
          {
            genericId: '',
            id: '',
            franchiseNormalCourses: []
          }
        ];
      },

      'data.student.taxpayerId'(value) {
        if (this.data.isResponsible && this.hasTaxpayerUserFetch(value)) {
          this.$store.dispatch('getPersonByTaxpayerId', {
            document: value
          })
            .then(res => {
              this.data.student.name = res.person.NomePessoaNomeFantasia;
            })
            .catch(() => {
              this.disableStudentNameField = false;
            });
        } else {
          this.data.student.name = '';

          this.disableStudentNameField = true;
        }
      },

      'data.franchiseId'(value) {
        this.toggleModality('normal');
        this.showModality = value != '653';

        if (!this.showModality) {
          this.toggleModality('digital');
        } else {
          this.enableToggleCards();
          this.fetchCourses();
        }
      },

      'data.startDate'(value) {
        this.disableEndDate = false;

        if (value == '' || value == null) {
          this.disableEndDate = true;
          this.data.endDate = '';

          this.$refs.endDateField.resetField();

          this.resetTimesField();
        } else {
          this.data.startWeekday = this.$service.capitalizeFirstLetter(moment(value).format('dddd'));
        }
      },

      'data.endDate'(value) {
        this.startTimesArr = this.createTimeArr();
        this.disableStartTime = false;

        if (value == '' || value == null) {
          this.resetTimesField();
        }
      },

      'data.startTime'(value) {
        this.endTimesArr = this.createTimeArr(value, '21:00', true);
        this.disableEndTime = false;
      },

      'data.payment.amount'(value) {
        if (value && value > 0) {
          this.disableCourseDiscountAmountField = false;
        } else {
          this.disableCourseDiscountAmountField = true;
          this.$refs.courseDiscountField.resetField();
          this.disableCourseInstallment = true;
        }
      },

      'data.payment.amountWithDiscount'(value) {
        if (value && value > 0) {
          this.disableCourseInstallment = false;
        }

        this.totalCourseAmount = this.formatLabelAmount(this.data.payment.installments, value);
      },

      'data.payment.installments'(value) {
        this.totalCourseAmount = this.formatLabelAmount(value, this.data.payment.amountWithDiscount);
      },

      'data.courseware.amount'(value) {
        if (value && value > 0) {
          this.disableCoursewareDiscountAmountField = false;
        } else {
          this.disableCoursewareDiscountAmountField = true;
          this.$refs.coursewareDiscountField.resetField();
          this.disableCoursewareInstallment = true;
        }
      },

      'data.courseware.amountWithDiscount'(value) {
        if (value && value > 0) {
          this.disableCoursewareInstallment = false;
        }

        this.totalCoursewareAmount = this.formatLabelAmount(this.data.courseware.installments, value);
      },

      'data.courseware.installments'(value) {
        this.totalCoursewareAmount = this.formatLabelAmount(value, this.data.courseware.amountWithDiscount);
      },

      'data.isTotalPayment'(value) {
        if (this.data.signal.amount != '') {
          this.data.hasSignal = !value;
        }
      }
    },

    methods: {
      formatFranchiseCourses(data) {
        data.franchiseNormalCourses = [];

        if (data.genericId) {
          this.$store.dispatch('getCourses', {
            generic_id: data.genericId,
            franchise_id: this.data.franchiseId,
            limit_items: 5000
          })
            .then(res => {
              data.franchiseNormalCourses = this.parseCoursesData(res.items);
            })
            .catch(() => {
              this.alertCardData = {
                text: 'Erro ao carregar os cursos da franquia',
                color: '#FF5A5A'
              };

              this.showAlertCard = true;
            });
        }
      },

      addCourse() {
        this.data.courses.push({
          id: '',
          genericId: '',
          franchiseNormalCourses: []
        });
      },

      removeCourse(index) {
        this.data.courses.splice(index, 1);
      },

      hasCourseIdEmpty() {
        return !!this.data.courses.find(item => item.id == '');
      },

      hasSomeCourseIdSelected() {
        return !!this.data.courses.find(item => item.id != '');
      },

      fetchCourses() {
        if (!this.isDigital() && this.data.franchiseId) {
          this.fetchGenericCoursesByFranchise(this.data.franchiseId);
        } else {
          this.fetchCoursesDigital();
        }
      },

      hasTaxpayerUserFetch(value) {
        return this.$service.validateTaxpayerId(value) && this.$service.removeSpecialCharacter(value).length == 11;
      },

      resetResponsibleData() {
        this.data.name = '';
        this.data.phone = '';
        this.data.email = '';
      },

      async fetchGenericCoursesByFranchise(franchiseId) {
        this.franchiseGenericCourses = [];

        await this.$store.dispatch('getCoursesByFranchise', {
          franchise_id: franchiseId,
          limit_items: 5000
        })
          .then(res => {
            this.franchiseGenericCourses = this.parseCoursesData(res.items);
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao carregar os cursos genéricos da franquia',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      async fetchMedias() {
        await this.$store.dispatch('getMedias')
          .then(res => {
            const { items } = res;

            this.medias = items.map(item => {
              return {
                label: item.Descricao,
                value: item.Codigo
              };
            });
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao buscar mídias',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      async fetchCoursesDigital() {
        this.franchiseDigitalCourses = [];

        await this.$store.dispatch('getCoursesDigital', {
          limit_items: 5000
        })
          .then(res => {
            this.franchiseDigitalCourses = this.parseCoursesData(res.items);
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao carregar os cursos IM Digital da franquia',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      submit() {
        this.isSummary = !this.isSummary;
      },

      async createCheckout() {
        this.hasRequest = true;

        var courses = this.getSelectedCoursesDetail();

        var parsedCourses = courses.map(item => {
          return {
            id: item.value,
            description: item.label
          };
        });

        var contractData = {
          data: {
            name: this.data.name,
            document: this.data.taxpayerId,
            telephone: this.data.phone,
            email: this.data.email,
            enrollment_fee: this.data.amountEnrollment,
            due_date: moment(this.data.dueDate).format('YYYY-MM-DD'),
            collaborator_id: this.getCollaboratorId,
            franchise_id: this.data.franchiseId,
            is_digital: Number(this.data.isDigital),
            media_id: this.data.mediaId ?? 99,
          },

          address: {
            zipcode: this.data.postalCode,
            street: this.data.address.line1,
            district: this.data.address.neighborhood,
            city: this.data.address.city,
            state: this.data.address.stateCode,
          },

          courses: parsedCourses,

          is_total_payment: Number(this.data.isTotalPayment),

          payment: {
              value: this.data.payment.amount,
              value_with_discount: this.data.payment.amountWithDiscount,
              due_date: moment(this.data.payment.dueDate).format('YYYY-MM-DD'),
              installments: this.data.payment.installments
            }
        };

        if (!this.data.isDigital) {
          contractData.data.start_weekday = this.data.startWeekday;
          contractData.data.start_date = moment(this.data.startDate).format('YYYY-MM-DD');
          contractData.data.end_date = moment(this.data.endDate).format('YYYY-MM-DD');
          contractData.data.start_time = this.data.startTime;
          contractData.data.end_time = this.data.endTime;
        }

        if (this.data.isResponsible) {
          contractData.is_responsible = 1;
          contractData.student = {
            name: this.data.student.name,
            document: this.data.student.taxpayerId
          };
        }

        if (this.data.hasSignal) {
          contractData.is_signal = 1;
          contractData.signal = {
            value: this.data.signal.amount,
            due_date: moment(this.data.signal.dueDate).format('YYYY-MM-DD')
          };
        }

        if (this.data.isCourseware) {
          contractData.is_courseware = 1;
          contractData.courseware = {};
          contractData.courseware.payment = {
            value: this.data.courseware.amount,
            value_with_discount: this.data.courseware.amountWithDiscount,
            due_date: moment(this.data.courseware.dueDate).format('YYYY-MM-DD'),
            installments: this.data.courseware.installments
          };
        }

        await this.$store.dispatch('postCreateContract', contractData)
          .then(res => {
            if (this.getIsExternalAuth) {
              contractData.contract_id = res.contract.Codigo;
              contractData.sign_url = `${window.location.origin}/precontract/${res.contract.Codigo}/contract/sign`;

              parent.postMessage({
                type: 'im-contract-created-successfully',
                data: contractData
              }, '*');
            }

            this.$router.push({
              name: 'newSuccess',
              params: {
                contractId: res.contract.Codigo
              }
            });
          })
          .catch(() => {
            if (this.getIsExternalAuth) {
              parent.postMessage({ type: 'im-contract-created-error' }, '*');
            }

            this.alertCardData = {
              text: 'Erro ao criar contrato. Tente novamente!',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });

        this.hasRequest = false;
      },

      formatLabelAmount(installment, value) {
        return 'Total: ' + this.$service.formatBrlAmountSymbol(installment * value ?? 0);
      },

      async fetchPostalCode() {
        if (!this.hasPostalCodeRequest) {
          this.hasPostalCodeRequest = true;

          await this.$store.dispatch('getPostalCode', this.$service.removeSpecialCharacter(this.data.postalCode))
            .then(res => {
              this.data.address.line1 = res.address_line_1;
              this.data.address.city = res.city;
              this.data.address.neighborhood = res.neighborhood;
              this.data.address.stateCode = res.state_code;
            })
            .catch(() => {
              this.data.address = this.initialAddress();
              this.$refs.postalCode.inputButtonClick();

              this.alertCardData = {
                text: 'CEP inválido. Tente novamente!',
                color: '#FF5A5A'
              };

              this.showAlertCard = true;
            });

            this.hasPostalCodeRequest = false;
        }
      },

      initialAddress() {
        return {
          line1: '',
          city: '',
          neighborhood: '',
          stateCode: ''
        };
      },

      parseCoursesData(courses) {
        return courses.map(item => ({
          label: item.Descricao,
          value: item.Codigo
        }));
      },

      getMidiaLabel(mediaId) {
        if (!mediaId || mediaId == 99) {
          return 'Pré - Matrícula';
        }

        var currentMediaData = this.medias.find(item => item.value == mediaId);

        return currentMediaData.label;
      },

      mountInputMoneyRules(param, min, max) {
        return 'required|notZero|' + param + ':' + min + ':' + max;
      },

      createTimeArr(start = '07:00', end = '21:00', setInterval = false) {
        var arr = [];
        var startTimeSplit = start.split(':');
        var startTime = parseInt(startTimeSplit[0]);
        var isStartHalfAnHour = parseInt(startTimeSplit[1]) == 30;

        var endTimeSplit = end.split(':');
        var endTime = parseInt(endTimeSplit[0]);

        var interval = endTime - startTime;

        for (var i = 0; i <= interval; i++) {
          var defaultTime = startTime + i;
          var currentTime = setInterval ? defaultTime + 1 : defaultTime;

          if (i == 0 && !isStartHalfAnHour || i != 0) {
            arr.push({
              label: currentTime + ':00',
              value: currentTime + ':00:00'
            });
          }

          if (i != interval) {
            arr.push({
              label: currentTime + ':30',
              value: currentTime + ':30:00'
            });
          }
        }

        return arr;
      },

      toggleModality(value) {
        var currentItem = this.modalityCardData.find(item => item.value == value);

        if (!currentItem.isActive || !currentItem.isDisabled) {
          this.modalityCardData.map(item => item.isActive = false);
          this.modalityCardData.find(item => item.value == value).isActive = true;

          this.data.isDigital = this.isDigital();
          this.fetchCourses();
        }
      },

      resetTimesField() {
        this.disableStartTime = true;
        this.disableEndTime = true;

        this.data.startTime = '';
        this.data.endTime = '';
        this.data.startWeekday = '';
      },

      isDigital() {
        return this.modalityCardData.find(item => item.value == 'digital').isActive;
      },

      enableToggleCards() {
        this.modalityCardData.map(item => item.isDisabled = false);
      },

      disableToggleCards() {
        this.modalityCardData.map(item => item.isDisabled = true);
      },

      getSelectedCoursesDetail() {
        return this.data.courses.map(item => {

          const matchingCourse = this.isDigital() ? this.franchiseDigitalCourses.find(course => course.value == item.id)
            : item.franchiseNormalCourses.find(course => course.value === item.id);

          return matchingCourse ? matchingCourse : [];
        });
      },

      parseDebitCourseAmount() {
        let totalAmount = this.parseTotalCourseAmount();

        totalAmount -= this.data.amountEnrollment;

        return totalAmount;
      },

      parseTime(time) {
        return time.split(':').slice(0, 2).join(':');
      },

      parseTotalCourseAmount() {
        let totalAmount = 0;

        totalAmount += this.data.amountEnrollment;
        totalAmount += this.data.payment.amountWithDiscount * this.data.payment.installments;

        if (this.data.isCourseware) {
          totalAmount += this.data.courseware.amountWithDiscount * this.data.courseware.installments;
        }

        return totalAmount;
      },

      getFranchiseLabel(franchiseId) {
        var currentFranchiseData = this.getFranchisesCollaborator.find(item => item.value == franchiseId);

        return franchiseId && currentFranchiseData ? currentFranchiseData.label : 'Selecione';
      },

      toggleTotalPayment()  {
        if (!this.hasRequest) {
          this.data.isTotalPayment = !this.data.isTotalPayment;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    justify-content: center;

    .content {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      padding: 0 1.5rem;
      max-width: 672px;
      width: 100%;

      .form-container {
        form {
          width: 100%;
        }
      }

      .default-section-component {
        .field-container {
          .section {
            p {
              font-size: 12px;
              color: #6D6F6F;
              margin-top: 2rem;
            }

            .row {
              margin-top: 1rem;
              margin-bottom: 2rem;
              display: flex;
              column-gap: 1rem;

              .row-content {
                display: flex;
                gap: 1rem;

                &.full {
                  width: 100%;
                }
              }

              .action-container {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                i {
                  color: #FF8B00;
                }

                .icon-remove {
                  color: #FF5A5A;
                }
              }

              .items {
                display: contents;
              }

              .input-field {
                width: 100%;

                &.x-large {
                  max-width: 22rem;
                }

                &.large {
                  max-width: 14rem;
                }

                &.medium {
                  max-width: 12rem;
                }

                &.small {
                  max-width: 10rem;
                }

                &.time {
                  max-width: 7.5rem;
                }
              }

              .range-field {
                width: 100%;
              }

              .toggle-card {
                display: flex;

                .card {
                  height: 48px;
                  padding: 1rem;
                  margin-right: 1rem;
                  border-radius: 6px;
                  border: 1px solid #DADADA;
                  background: #FFF;
                  display: flex;
                  align-items: center;
                  color: #333;
                  cursor: pointer;
                  text-align: center;
                  font-size: 14px;

                  &:last-child {
                    margin-right: 0;
                  }

                  &.disable {
                    cursor: not-allowed;
                    background: #F9F9F9;
                    opacity: 0.8;
                  }

                  &.active {
                    border: 1px solid #FF8B00;
                    background: #FF8B00;
                    color: #FFF;
                    opacity: 1.0;

                    p {
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }

        .summary-container {
          margin-top: 1rem;
          font-weight: 300;

          .summary-section {
            margin-bottom: 2rem;

            .item {
              display: flex;
              margin-top: 0.5rem;

              p {
                font-size: 16px;
              }

              &.action {
                flex-direction: unset;

                .toggle-switch-component {
                  margin-left: auto;
                }
              }

              .label {
                font-weight: 400;
                margin-right: 2rem;
              }

              .value {
                color: #333;
                margin-left: auto;
              }
            }

            .divider {
              height: 1px;
              background: #DADADA;
              margin-top: 1rem;
              margin-bottom: 1rem;
            }
          }
        }
      }

      .button-container {
        margin-top: 1rem;
        justify-content: center;
        display: flex;
        width: 100%;
        gap: 2.5rem;

        .button-component {
          width: 200px;

          &.left {
            margin-left: auto;
          }
        }
      }
    }

    .alert-card-component {
      margin-top: 0.5rem;
    }
  }

  @media (max-width: 755px) {
    .container {
      .content {
        .form-container {
          form {
            width: 100%;
          }
        }

        .default-section-component {
          .field-container {
            .section {
              .row {
                display: flex;
                flex-direction: column;

                &.action {
                  flex-direction: row;
                }

                .row-content {
                  flex-direction: column;
                  max-width: 20rem;
                }

                .items {
                  &:first-child {
                    margin-bottom: 2rem;
                  }

                  .input-field {
                    margin-bottom: 0rem;
                  }
                }

                .items {
                  display: flex;

                  &:first-child {
                    margin-bottom: 2rem;
                  }
                }

                .input-field {
                  margin-bottom: 2rem;
                  margin-right: 0.5rem;

                  &.x-large {
                    max-width: 20rem;
                  }

                  &:last-child {
                    margin-bottom: 0;
                    margin-left: 0;
                    margin-right: 0;
                  }
                }
              }
            }
          }

          .summary-container {
            .item {
              flex-direction: column;
              margin-top: 1rem !important;

              p {
                font-size: 14px !important;
              }

              .value {
                margin-left: unset !important;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .container {
      .content {
        padding: 0 1rem;

        .form-container {
          form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .default-section-component {
          .field-container {
            .section {
              .row {
                .items {
                  justify-content: space-between;
                }

                .input-field {
                  &.time {
                    width: 100%;
                    max-width: unset;
                  }
                }

                .toggle-card {
                  .card {
                    width: 100%;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }

        .button-container {
          .button-component {
            width: 100%;

            &.left {
              margin-left: unset;
            }
          }
      }
    }
  }
}
</style>