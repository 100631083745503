<template>
  <ValidationProvider
    :rules="rules"
    v-slot="{ errors }"
    tag="div"
    class="checkbox-container"
    :class="{ 'disabled': isDisabled }"
  >
    <input
      type="checkbox"
      :value="value"
      v-model="toggleValue"
      :disabled="isDisabled"
      :class="{ 'error': errors[0] }"
    />

    <div class="label-container" :class="{ 'error': errors[0] }">
      {{ label }}
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'CheckboxComponent',

    data() {
      return {
        toggleValue: this.value
      };
    },

    props: {
      value: {
        type: [String, Boolean],
        default: false,
      },

      rules: {
        type: [Object, String],
        default: '',
      },

      label: {
        type: String,
        default: 'label'
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ValidationProvider
    },

    watch: {
      toggleValue(value) {
        this.$emit('input', value);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .checkbox-container {
    display: flex;

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    input {
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
      position: relative;
      border: 0;
      outline: 0;
      cursor: pointer;

      &.error {
        &:before {
          border: 2px solid #b93a5b;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 3px;
        top: 0;
        left: 0;
        background: #FFF;
        border: 2px solid #f5a939;
      }

      &:checked:before {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 3px;
        top: 0;
        left: 0;
        background: #f5a939;
        border: 2px solid #f5a939;
      }

      &:checked:after {
        content: '';
        display: block;
        width: 2px;
        height: 8px;
        border: solid #FFF;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 6px;
      }
    }

    .label-container {
      margin-left: 0.5rem;
      font-size: 14px;
      margin-top: -2px;

      &.error {
        color: #b93a5b;
      }
    }
  }
</style>